#blog-page{
    margin-top: 30px;
    .body-link{
        font-size: 15px !important;
    }
    .entry-date{
        font-size: 12px !important;

    }
    .entry-title.listPosts {
        font-size: 30px !important;
    }
    .entry-header{
        // height: 30px;
    }
    .flow-text{
        p{
            margin: 0px !important;
            font-size: 16px;
        }
    }
    .listaFooter{
        display: none;
        height: 20px;
    }
    .post{
        margin-bottom: 20px;
        padding-right: 20px;
        p{
            padding-top: 10px; 
            padding-bottom: 10px; 
        }
    }
    .thumbImgCont{
        overflow: hidden;
        max-height: 250px;
        margin-bottom: 10px;
        @extend .transicao;

        img{
            width: 100%;
            height: auto !important;
        }
    }
    .thumbImgCont:hover{
        opacity: .6;
        @extend .transicao;
    }

}