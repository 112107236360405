
#nossosempreendimentos{
    position: relative;
    min-height: 600px;
    padding-bottom: 100px;  
    background: transparent url( assets/img/nossosempreendimentos/bg.jpg ) repeat-x center bottom;
    padding-top: 40px;
    h2{
        text-align: center;
        color: $cor2;
        font-size: 20px;
        text-transform: uppercase;
    padding-bottom: 40px;  

    }
    .btn-h{
        display: inline-block;
        padding: 15px 10px;
        border-radius: 5px;
        text-align: center;
        color: $cor3;
        text-transform: uppercase;
        font-size: 20px;
        padding-right: 60px;
        position: relative;
        margin: 0 10px 10px 10px;
        img{
            position: absolute;
            right: .75rem;
            width: 40px;
            top: 10px;
        }
    }
    .btn-acts{
        text-align: center;
        padding: 100px 0px 40px 0px;
    }
    .btn-1{
        background: $cor2;
    }
    .btn-2{
        background: $cor5;
        
    }
    .container{
        z-index: 1;
        position: relative;
    }
    .box-back{
        position: absolute;
        z-index: 0;
        top: 430px;
        height: 400px;
        width: 100%;
        left: 0px;
        margin-top: -200px;
        background: $cor6;
    }
    .empreendimento{
        text-align: center;
        h3{
            font-size: 20px;
            text-align: center;
            font-weight: bold;
        }
        
        .boxemp{
            width: 80%;
            display: inline-block;
            max-width: 400px;
            position: relative;    
            @extend .transicao;

            .peliculaEmp{
            @extend .transicao;

                width: 0%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                opacity: 0;
                overflow: hidden;
                background: rgba($color: #000000, $alpha: .8);
                padding: 60px 0 0 0;
                p{
                    color: #fff;
                }
            }

            img{
                width: 100%;
            }

            .footerTit{
                width: 100%;
 
                background: $cor1;
                margin-top: -6px;
                padding: 10px;
                text-align: left;
                color: $cor3;
                position: relative;
                span{
                    position: absolute;
                    right: .75rem;
                    i{
                        margin-right: 5px;
                    }
                }
            }

        }

        .boxemp:hover{
            @extend .transicao;
            .peliculaEmp{
                @extend .transicao;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                background: rgba($color: #000000, $alpha: .8);
                padding: 60px 0 0 0;
                opacity: 1;
                overflow: initial;
                p{
                    color: #fff;
                }
            }
        }
    }


}


#empreendimentos{
    padding: 60px 0px;
    .empreendimento{
        text-align: center;
        margin-bottom: 60px;
        h3{
            font-size: 20px;
            text-align: center;
            font-weight: bold;
        }
        
        .boxemp{
            width: 80%;
            display: inline-block;
            max-width: 400px;
            position: relative;    
            @extend .transicao;

            .peliculaEmp{
            @extend .transicao;

                width: 0%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                opacity: 0;
                overflow: hidden;
                background: rgba($color: #000000, $alpha: .8);
                padding: 60px 0 0 0;
                p{
                    color: #fff;
                }
            }

            img{
                width: 100%;
            }

            .footerTit{
                width: 100%;
 
                background: $cor1;
                margin-top: -6px;
                padding: 10px;
                text-align: left;
                color: $cor3;
                position: relative;
                span{
                    position: absolute;
                    right: .75rem;
                    i{
                        margin-right: 5px;
                    }
                }
            }

        }

        .boxemp:hover{
            @extend .transicao;
            .peliculaEmp{
                @extend .transicao;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                background: rgba($color: #000000, $alpha: .8);
                padding: 60px 0 0 0;
                opacity: 1;
                overflow: initial;
                p{
                    color: #fff;
                }
            }
        }
    }
}











@media only screen and (max-width: 996px) {


    #nossosempreendimentos{
        position: relative;
        min-height: 600px;
        padding-bottom: 100px;  
        background: transparent url( assets/img/nossosempreendimentos/bg.jpg ) repeat-x center bottom;
        padding-top: 40px;
        h2{
            text-align: center;
            color: $cor2;
            font-size: 20px;
            text-transform: uppercase;
        padding-bottom: 40px;  
    
        }
        .btn-h{
            display: inline-block;
            padding: 15px 10px;
            border-radius: 5px;
            text-align: center;
            color: $cor3;
            text-transform: uppercase;
            font-size: 20px;
            padding-right: 60px;
            position: relative;
            margin: 0 10px 10px 10px;
            img{
                position: absolute;
                right: .75rem;
                width: 40px;
                top: 10px;
            }
        }
        .btn-acts{
            text-align: center;
            padding: 100px 0px 40px 0px;
        }
        .btn-1{
            background: $cor2;
        }
        .btn-2{
            background: $cor5;
            
        }
        .container{
            z-index: 1;
            position: relative;
        }
        .box-back{
            position: absolute;
            z-index: 0;
            top: 430px;
            height: 400px;
            width: 100%;
            left: 0px;
            margin-top: -200px;
            background: $cor6;
            display: none;
        }
        .empreendimento{
            text-align: center;
            margin-bottom: 40px;
            h3{
                font-size: 20px;
                text-align: center;
                font-weight: bold;
            }
            
            .boxemp{
                width: 80%;
                display: inline-block;
                max-width: 400px;
                position: relative;    
         
    
                .peliculaEmp{
                
    
                    width: 0%;
                    height: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    opacity: 0;
                    overflow: hidden;
                    background: rgba($color: #000000, $alpha: .8);
                    padding: 60px 0 0 0;
                    p{
                        color: #fff;
                    }
                }
    
                img{
                    width: 100%;
                }
    
                .footerTit{
                    width: 100%;
     
                    background: $cor1;
                    margin-top: -6px;
                    padding: 10px;
                    text-align: left;
                    color: $cor3;
                    position: relative;
                    span{
                        position: absolute;
                        right: .75rem;
                        i{
                            margin-right: 5px;
                        }
                    }
                }
    
            }
    
            .boxemp:hover{
                
                .peliculaEmp{
                   
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    background: rgba($color: #000000, $alpha: .8);
                    padding: 60px 0 0 0;
                    opacity: 1;
                    overflow: initial;
                    p{
                        color: #fff;
                    }
                }
            }
        }
    
    
    }
}