#header{
    background: transparent url( assets/img/header/bg-det.jpg ) repeat-x ;
    height: 100px;
    width: 100%;
    position: relative;
    margin: 0px !important;

    #marcab{
        padding-top: 25px;
        padding-left: 1.5rem;
        #marca{
            height: 50px;
        }
    }


    #nav{
        position: relative;
        padding: 0px;
        height: 100%;
        text-align: center;
        ul{
            margin: 0px;
            padding: 0px;
            height: 100%;
            display: inline-block;
            li.diferente{
                font-size: 12px;
                font-weight: bold;
                display: inline-block;
                height: 100%;
                position: relative;
                padding: 0 5px;
                margin: 0 10px;
                a{
                    text-transform: uppercase;
                    color: $cor5;
                    @extend .transicao;
                    line-height: 100px;
                }
                a:hover{
                    color: $cor5;
                    @extend .transicao;
                }
            }
            li{
                font-size: 12px;
                font-weight: bold;
                display: inline-block;
                height: 100%;
                position: relative;
                padding: 0 5px;
                margin: 0 10px;
                a{
                    text-transform: uppercase;
                    color: $cor1;
                    @extend .transicao;
                    line-height: 100px;
                }
                a:hover{
                    color: $cor2;
                    @extend .transicao;
                }
            }
            li::after{
                background: transparent;
                height: 2px;
                position: absolute;
                bottom: 0px;
                left: 30%;
                width: 40%;   
                content: "";   
                @extend .transicao;  
                         
            }
            li.current-menu-item::after{
                background: $cor2;
                height: 10px;
                position: absolute;
                bottom: 0px;
                left: 30%;
                width: 40%;  
                content: "";   
                @extend .transicao; 
                     
            }
            li:hover::after{
                background: $cor2;
                height: 10px;
                position: absolute;
                bottom: 0px;
                left: 30%;
                width: 40%;  
                // width: 30px;  
                content: "";   
                @extend .transicao;  
                // left: 50%;
                // margin-left: -15px;            
            }
        }
    }

    #redes-sociais{
        ul{
            margin: 10px 0 0 0;
            padding: 10px;
            height: 100%;
            float: right;
            border-radius: 5px;
            border: solid 1px #ccc;
            min-width: 170px;
            .rs{
                height: 25px;
            }
            li{
                display: inline-block;
                line-height: 0px;
                text-align: center;
                padding: 0 5px;
                

                a{
                   
                }

                a:hover{
                   
                }
                img{
                    width: 40px;
                    display: inline-block;
                    @extend .transicao;
                }
                img:hover{
                    opacity: .7;
                    @extend .transicao;
                }
                span{
                        font-weight: bold;
                        display: inline-block;
                        text-transform: uppercase;
                        font-size: 12px;
                        margin: 0px;
                        line-height: 0px;
                        height: 10px;
                        margin-top: 29px;
                 
                }
            }
        }

    }
}


@media only screen and (max-width: 1287px) {
    #header{
        background: transparent url( assets/img/header/bg-det.jpg ) repeat-x ;
        height: 100px;
        width: 100%;
        position: relative;
        margin: 0px !important;
    
        .container{
            width: 100%;
        }
        #marcab{
            padding-top: 25px;
            padding-left: 1.5rem;
            #marca{
                height: 50px;
            }
        }
    
    
        #nav{
            position: relative;
            padding: 0px;
            height: 100%;
            text-align: center;
            ul{
                margin: 0px;
                padding: 0px;
                height: 100%;
                display: inline-block;
                li.diferente{
                    font-size: 12px;
                    font-weight: bold;
                    display: inline-block;
                    height: 100%;
                    position: relative;
                    padding: 0 5px;
                    margin: 0 10px;
                    a{
                        text-transform: uppercase;
                        color: $cor5;
                    
                        line-height: 100px;
                    }
                    a:hover{
                        color: $cor5;
                   
                    }
                }
                li{
                    font-size: 12px;
                    font-weight: bold;
                    display: inline-block;
                    height: 100%;
                    position: relative;
                    padding: 0 5px;
                    margin: 0 10px;
                    a{
                        text-transform: uppercase;
                        color: $cor1;
                    
                        line-height: 100px;
                    }
                    a:hover{
                        color: $cor2;
                   
                    }
                }
                li::after{
                    background: transparent;
                    height: 2px;
                    position: absolute;
                    bottom: 0px;
                    left: 30%;
                    width: 40%;   
                    content: "";   
               
                             
                }
                li.current-menu-item::after{
                    background: $cor2;
                    height: 10px;
                    position: absolute;
                    bottom: 0px;
                    left: 30%;
                    width: 40%;  
                    content: "";   
                 
                         
                }
                li:hover::after{
                    background: $cor2;
                    height: 10px;
                    position: absolute;
                    bottom: 0px;
                    left: 30%;
                    width: 40%;  
                    // width: 30px;  
                    content: "";   
                    
                    // left: 50%;
                    // margin-left: -15px;            
                }
            }
        }
    
        #redes-sociais{
            ul{
                margin: 10px 0 0 0;
                padding: 10px;
                height: 100%;
                float: right;
                border-radius: 5px;
                border: solid 1px #ccc;
                .rs{
                    height: 25px;
                }
                li{
                    display: inline-block;
                    line-height: 0px;
                    text-align: center;
                    padding: 7px 5px 0 5px;
                    
    
                    a{
                       
                    }
    
                    a:hover{
                       
                    }
                    img{
                        width: 40px;
                        display: inline-block;
                         
                    }
                    img:hover{
                        opacity: .7;
                        
                    }
                    span{
                        display: none;

                    }
                }
            }
    
        }
    }

}