#comments{
    padding-top: 30px;
    background: rgba(0,0,0,.02);
    .comments-title{
        font-size: 20px;
    }
    #reply-title{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
    }
}