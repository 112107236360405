#noticias{
    margin-bottom: 0px;
    background: $cor1;
    padding: 50px 0 70px 0;

    #titNovidades{
        position: relative;
        margin-bottom: 20px;
        h2{
            color: $cor3;
            font-weight: 300;
        }
        span{
            position: absolute;
            right: .75rem;
            top: 10px;
            color: $cor3;
        }

    }
    

    .noticia{
        position: relative;
        img{
            width: calc(100% - 2px);
            height: auto;
            margin-left: 1px;
            margin-bottom: -10px;
        }
        .pelicula{
            position: relative;
            width: calc(100% - 2px);
            margin-left: 1px;            
            min-height: 50px;
            background: #fff;
            @extend .transicao;
            p{
                color: #000;
                position: absolute;
                bottom: 0px;
                padding: 0 .75rem;
            }
        }
        .pelicula:hover{
            background: rgba($color: #000000, $alpha: .8);
            @extend .transicao;

            p{
                color: #fff;
                position: absolute;
                bottom: 0px;
                padding: 0 .75rem;
            }
        }
    }
    .noticia:hover{
        position: relative;
        img{
            width: calc(100% - 2px);
            height: auto;
            margin-left: 1px;
            margin-bottom: -10px;
        }
        .pelicula{
            background: rgba($color: #000000, $alpha: .8);
            @extend .transicao;

            p{
                color: #fff;
                position: absolute;
                bottom: 0px;
                padding: 0 .75rem;
            }
        }
    }
}