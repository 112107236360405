#sidebar-right{
    // background: #f0f4d5;
    padding: 0px 0;
     .sidebar-area{
        //  background: 
         color: $cor2;
         .widget{
             .sidebar-text{
                 padding: 10px 0;
                 color:  $cor2;
                 background:rgba(0,0,0,0.05);
                 font-size: 18px;
                 margin: 0px !important;
             }
             .divider{
                 display: none;
             }
             ul:not(.browser-default) {
                padding-left: 0px;
            }
            ul:not(.browser-default) li {
                list-style-type: none;
                // padding-left: 20px;
            }
             ul{
                margin: 0px 0px 10px 0px;
                
                li{
                    a{
                        display: block;
                        padding: 10px 5px;
                        font-size: 12px;
                        background: rgba($color: #000000, $alpha: .0);
                        @extend .transicao;
                    }
                    a:hover{
                        padding: 10px 5px;
                        font-size: 12px;
                        background: rgba($color: $cor2, $alpha: .8);
                        color: #fff;
                        @extend .transicao;
                    
                    }
                }
                
             }
         }
     }

}