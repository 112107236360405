
#footer{
    background: $cor1;




#assinatura{
        padding: 50px 0rem 0 0;
    #marca-footer{
        width: 120px;
        margin-top: 0px;
    }
    .menu-footer{
        margin-bottom: 40px;
        text-align: center;
        ul{
            list-style: none;
            padding:0px;
            li{
                display: inline-block;
                padding: 0 20px 0 0;
                a{
                    text-transform: uppercase;
                    color: $cor3;
                    font-size: 14px;
                    @extend .transicao;
                }
                a:hover{
                    text-transform: uppercase;
                    color: $cor2;
                    font-size: 14px;
                    @extend .transicao;
                }
            }
        }

        
    }
    .endereco-footer{
        margin-top: 50px;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: center;
        p{
            color: $cor3 !important;

        }
        span{
            color: $cor5;
            font-size: 12px;
            margin-left: 5px;
        }
        .rsfooter{
            list-style: none;
            padding: 50px 0 0px 0;
            text-align: center;
            margin: 0px;
            li{
                display: inline-block;
                img{
                    width: 30px;
                    margin: 0 10px;
                }
            }
        }
    }
    .email{
        position: relative;
        p{
            color: $cor5 !important;
            font-size: 16px;
        }
        
    }
   
}
.assin{
    margin-top: 50px;
    padding-top: 21px;
    background: $cor4;
    text-align: center;
    img{
        width: 40px;
        margin-top: 0px;
        margin-bottom: 10px;
    }
}
}



@media only screen and (max-width: 996px) {
    #footer{
       
        .menu-footer{
            margin-bottom: 40px;
            text-align: center;
            ul{
                list-style: none;
                padding:30px 0px 0px 0px !important;
                li{
                    width: 100%;
                    display: inline-block;
                    padding: 5px 0px 5px 0 !important;
                    a{
                        text-transform: uppercase;
                        color: $cor3;
                        font-size: 18px;
                       
                    }
                    a:hover{
                        text-transform: uppercase;
                        color: $cor2;
                        font-size: 14px;
                       
                    }
                }
            }
    
            
        }
        #assinatura{
                padding: 50px .75rem;
            #marca-footerb{
                text-align: center;
            #marca-footer{
                width: 180px;
                display: inline-block;
                margin-top: 22px;
            }
        }

            .endereco-footer{
                font-size: 16px;
                p{
                    color: $cor3 !important;

                }
                span{
                    color: $cor5;
                    font-size: 12px;
                }
            }
            
            .assin{
                padding-top: 21px;
                text-align: center;
                img{
                    width: 40px;
                    margin-top: 20px;
                }
            }
        }
        }
}
