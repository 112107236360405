$cor1: #595959; 
$cor2: #fc8c3f; 
$cor3: #ffffff;
$cor4: #323232; 
$cor5: #ffb53c;
$cor6: #dadada;
$cor7: #999999;


#caminhoTit{
    p{
        // text-transform: uppercase;
        color: $cor7;
        font-size: 11px;
        padding: 0 .75rem;

    }
    
}


#btn-acts{
    text-align: center;
    padding: 0  60px 40px 0px;
    .btn-h{
        display: inline-block;
        padding: 15px 20px;
        border-radius: 5px;
        text-align: center;
        color: $cor3;
        text-transform: uppercase;
        font-size: 20px;
        padding-right: 60px;
        position: relative;
        margin: 0 10px 10px 10px;
        img{
            position: absolute;
            right: .75rem;
            width: 40px;
            top: 10px;
        }
    }
    .btn-acts{
        text-align: center;
        padding: 100px 0px 40px 0px;
    }
    .btn-1{
        
        background: $cor2;
    }
    .btn-2{
        background: $cor5;
        
    }
}


#modal1{
    border-top: 2px solid $cor2;
    h2 {
        font-size: 20px;
        text-align: center;
        color: $cor2;
        margin-top: 20px;
    }
    p {
        font-size: 16px;
        text-align: center;
        color: $cor4;
        margin: 0px 0px 50px 0px;
    }
    .btn-theme1{
        float: right;
        margin-right: .75rem;
        font-weight: bold;
        text-transform: uppercase;
   }
}

#search-2{
    position: relative;
    .input-field label:not(.label-icon).active {
        font-size: .8rem;
        -webkit-transform: translateY(-40%);
        transform: translateY(-40%);
    }
}
 /* label focus color */
 input[type=text]:focus + label {
    color: $cor2 !important;
}
/* label underline focus color */
    textarea:focus,
    input:focus {
    border-bottom: 1px solid $cor2 !important;
    box-shadow: 0 1px 0 0 $cor2 !important;
  }
.container{
    width: 90%;
    max-width: 1140px;
}
.saibamais{
    color: $cor2;
    text-decoration: underline;
}

 .wpcf7-checkbox{
    padding: 0px;
    .wpcf7-list-item{
        width: 33%;
        height: 20px;
    }
}

#detalhesCasaplantio{
    h3{
        font-size: 14px;
    }
}

#parceiros{
    margin-top: 30px;
    .bx-wrapper .bx-viewport {
        -moz-box-shadow: 0 0 0px #fff !important;
        -webkit-box-shadow: 0 0 0px #fff !important;
        box-shadow: 0 0 0px #fff !important;
        border: 0px solid #fff !important;
        left: -5px;
        background: #fff ;
        -webkit-transform: translatez(0);
        -moz-transform: translatez(0);
        -ms-transform: translatez(0);
        -o-transform: translatez(0);
        transform: translatez(0);
    }
    
.bx-wrapper .bx-controls-direction .bx-prev{
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    z-index: 9999;
    left: -30px;
}
.bx-wrapper .bx-controls-direction .bx-next{
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    z-index: 9999;
    right: -30px;
}
}
.localizacao{
    margin-top: 30px;
    width: calc(100% - 4px) !important;
    margin-left: 2px;
}

.flex-direction-nav{
    display: none !important;
}
#banner{
    ul:not(.browser-default) {
        padding-left: 0px;
    }
}
ul:not(.browser-default) {
    padding-left: 20px;
}
ul:not(.browser-default) li {
    list-style-type: disc;
    // padding-left: 20px;
}
.wp1s-main-wrapper .bx-wrapper .bx-pager {
    bottom: -24px;
}
.bx-wrapper .bx-viewport{
    background: transparent !important;
}
.wp1s-slider-wrapper .wp1s-slider-container{
    background: transparent !important;

}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
    position: relative !important;
    left: 0px !important;
    opacity: 1 !important;
}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
    // position: absolute;
    // opacity: 0;
    pointer-events: auto !important;
}
.row{
    margin: 0px;
}

.direita{
    
}

.wp-block-column{
    ul{
        list-style-type: disc !important;
        padding-left: 20px;
        li{
            padding: 5px 0;
            list-style-type: disc;
            color: $cor4;
        }
    }
}
body{
    font-family: 'Roboto', sans-serif;
}
p{
    // font-family: 'Roboto Condensed', sans-serif;
    color: $cor4;
}
h1,h2{
    font-weight: bold;
    color: $cor1;
    font-size: 30px;
    margin: 4px 0;
}
a{
    color: $cor1;
}
h3{
    // font-style:italic;
    color: $cor1;
    font-size: 26px;
}



.listPosts{
    font-size: 20px !important;
}

.btn-theme2{
    float: right;
    border-radius: 5px;
    color: $cor2;
    border: 1px solid $cor2 !important;
    background: $cor3;
    @extend .transicao;
    // border: none;
    padding: 0px 20px;
}
.btn-theme2:hover{
    border-radius: 5px;
    color: $cor3 !important;
    border: 1px solid $cor3 !important;
    background: $cor2 !important;
    @extend .transicao;
    // border: none;
    padding: 0px 20px;
}

.btn-theme1{
     border-radius: 5px;
     color: $cor2;
     background: $cor4;
     @extend .transicao;
     border: none;
     padding: 10px 20px;
}

.btn-theme1:hover{
    @extend .transicao;

    opacity: .8;
    color: $cor4;
     background: $cor2;
}
.btn-theme2{
    border: 1px solid $cor1;
    background: transparent;
    color: $cor1;
}
.btn-theme2:hover{
    border: 1px solid $cor1;
    background: transparent;
    color: $cor1;
}
.transicao{
    transition:all .3s linear !important;
    -webkit-transition:all .3s linear !important;
    -moz-transition:all .3s linear !important;
}


 



#slide-outBTN{
    line-height: 100px;
    cursor: pointer;
    color: $cor2;
    font-size: 35px !important;
}

#slide-out{
    list-style-type: none !important;
    padding-left: 0px !important;
    li{
        list-style-type: none !important;
        
    }    

    ul{
        list-style-type: none !important;
        padding-left: 0px;
        li{
            padding: 0;
            list-style-type: none;
            
        }
    }
    #marcaSidnav{
        text-align: center;
        img{
            width: 70%;
            margin: 15px 0 0 0;
        }
    }
    #redesSidNav{
        width: 100%;
        text-align: center;
        .redesSid{
            padding: 0 5px;
            .fab{
                font-size: 25px;
            }
        }
    }
}


.barratitulo{
    // background: url(assets/img/header/bg-det.jpg) repeat-x;
  background: url(assets/img/header/bg-subhead.jpg) repeat-x center;
  height: 100px;
    h1{
        line-height: 100px;
        margin: 0px;
        color: $cor3;
        position: relative;
        display: inline-block;
        padding: 0 .75rem;
    }

    h1::after{
        background: $cor2;
        height: 4px;
        position: absolute;
        bottom: 25px;
        left: .75rem;
        width: calc( 100% - 1.5rem );   
        content: "";   
        @extend .transicao;  
                 
    }

}



.btn-more{
    padding: 0 10px !important;
    font-size: 12px !important;
    line-height: 30px;
    height: 29px;
}

.contatoEnv{
    padding: 20px 0;
}

.orcamento{
    padding: 20px 20%;
}

#imagemDestaque{
    margin-bottom: 0px;
    padding: 40px 0;
    height: 400px;
    // background: transparent url() no-repeat fixed center 0%/cover;
    color: #fff;

    background: url(assets/img/temp/detalhe.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@import "blog/blog";
@import "blog/comments";
@import "blog/blog-page";
@import "blog/sidebar-area";

@import "base/header";

@import "home/banner";
@import "home/nossosempreendimentos";
@import "home/noticias";
@import "home/footer";
